import { SupportTechContainer, SupportTechTitle } from './styled';
import Slider from 'components/Slider';
import { supportTechImages } from './constants'


function SupportTech() {
    return (
        <SupportTechContainer className="container">
            <SupportTechTitle>Technologies We Support</SupportTechTitle>
            <Slider
                slidesPerView={4}
                isNavigation={true}
                isPagination={true}
                isAutoplay={true}
                autoPlayConfig={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                data={supportTechImages}
                card="isTechSupport"
                breakpoints={{
                    300: {
                        slidesPerView: 1,
                    },
                    1000: {
                        slidesPerView: 4,
                    },
                }}
                componentClassName="main-slider"
            />
        </SupportTechContainer>
    );
}

export default SupportTech;
