import { SubmenuProps } from './globalTypes';

import display from 'assets/icons/subheader-displays.svg';
import headphones from 'assets/icons/subheader-headphones.svg';
import cameras from 'assets/icons/subheader-cameras.svg';
import speaker from 'assets/icons/subheader-speakers.svg';
import microphones from 'assets/icons/subheader-microphones.svg';

import caseStudies from 'assets/icons/subheader-case-studies.svg';
import siteSurveyTool from 'assets/icons/subheader-site-survey-tool.svg';
import authorizedAgentPortal from 'assets/icons/subheader-authorized-agent.svg';

import installationServices from 'assets/icons/subheader-installation-services.svg';
import managedServices from 'assets/icons/subheader-managed-services.svg';
import supportDesk from 'assets/icons/subheader-support-desk.svg';

import scheduling from 'assets/icons/subheader-scheduling-panels.svg';
import conferenceBar from 'assets/icons/subheader-conference-bar.svg';
import wirelessPresentation from 'assets/icons/subheader-wireless-presentation.svg';
import touchDisplay from 'assets/icons/subheader-meeting-touch-displays.svg';
import securityCamera from 'assets/icons/subheader-security-cam.svg';
import ceilingMicrophone from 'assets/icons/subheader-ceiling-mics.svg';
import videoWall from 'assets/icons/subheader-video-walls.svg';
import DSP from 'assets/icons/subheader-DSP.svg';
import Phone from 'assets/icons/phone.svg';


import {
    scrollToInstallationServices,
    // scrollToManagedServices,
    scrollToSiteSurvey,
    scrollToResources,
    scrollToCoMarketing,
    scrollToDemo,
    scrollToAgentPortal
} from 'utils/scrollToComponent';

export const months = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];

export const menuItems: SubmenuProps[] = [
    {
        title: 'Blog',
        url: 'https://blog.profound-tech.com/blog',
        isExternal: true,
    },
    {
        title: 'Product Reviews',
        url: '/product-reviews',
    },
    {
        title: 'Design Hub',
        url: '/design-hub',
    },
    {
        title: 'Services Hub',
        url: '/services-hub',
    },
    {
        title: 'About Us',
        url: '/about-us',
    },
    {
        title: 'Contact Us',
        url: '/contact-us',
    },
    {
        title: 'Phone',
        url: 'tel:8772087308',
        img: Phone
    },
];

export const subMenuItems: any = {
    '/product-reviews': [
        {
            img: scheduling,
            title: 'Scheduling Panels',
            url: '',
            isFilter: true,
        },
        {
            img: conferenceBar,
            title: 'Conference Bar',
            url: '',
            isFilter: true,
        },
        {
            img: wirelessPresentation,
            title: 'Wireless Presentation',
            url: '',
            isFilter: true,
        },
        {
            img: display,
            title: 'Displays',
            url: '',
            isFilter: true,
        },
        {
            img: touchDisplay,
            title: 'Meeting Touch Displays',
            url: '',
            isFilter: true,
        },
        {
            img: headphones,
            title: 'Headphones',
            url: '',
            isFilter: true,
        },
        {
            img: cameras,
            title: 'Conferencing Camera',
            url: '',
            isFilter: true,
        },
        {
            img: securityCamera,
            title: 'Security Camera',
            url: '',
            isFilter: true,
        },
        {
            img: speaker,
            title: 'Speakers',
            url: '',
            isFilter: true,
        },
        {
            img: microphones,
            title: 'Microphones',
            url: '',
            isFilter: true,
        },
        {
            img: ceilingMicrophone,
            title: 'Ceiling Mics',
            url: '',
            isFilter: true,
        },
        {
            img: videoWall,
            title: 'Video Walls',
            url: '',
            isFilter: true,
        },
        {
            img: DSP,
            title: 'DSP',
            url: '',
            isFilter: true,
        },
    ],
    '/product-reviews/': [
        {
            img: scheduling,
            title: 'Scheduling Panels',
            url: '',
            isFilter: true,
        },
        {
            img: conferenceBar,
            title: 'Conferencing Bar',
            url: '',
        },
        {
            img: wirelessPresentation,
            title: 'Wireless Presentation',
            url: '',
            isFilter: true,
        },
        {
            img: display,
            title: 'Displays',
            url: '',
            isFilter: true,
        },
        {
            img: touchDisplay,
            title: 'Meeting Touch Displays',
            url: '',
            isFilter: true,
        },
        {
            img: headphones,
            title: 'Headphones',
            url: '',
            isFilter: true,
        },
        {
            img: cameras,
            title: 'Conferencing Camera',
            url: '',
            isFilter: true,
        },
        {
            img: securityCamera,
            title: 'Security Camera',
            url: '',
            isFilter: true,
        },
        {
            img: speaker,
            title: 'Speakers',
            url: '',
            isFilter: true,
        },
        {
            img: microphones,
            title: 'Microphones',
            url: '',
            isFilter: true,
        },
        {
            img: ceilingMicrophone,
            title: 'Ceiling Mics',
            url: '',
            isFilter: true,
        },
        {
            img: videoWall,
            title: 'Video Walls',
            url: '',
            isFilter: true,
        },
        {
            img: DSP,
            title: 'DSP',
            url: '',
            isFilter: true,
        },
    ],
    '/design-hub': [
        {
            img: caseStudies,
            title: 'Case Studies',
            url: '/downloads',
        },
        {
            img: siteSurveyTool,
            title: 'Site Survey Tool',
            onClick: () => scrollToSiteSurvey(),
        },
        {
            img: authorizedAgentPortal,
            title: 'Authorized Agent Portal',
            onClick: () => scrollToAgentPortal(),
        },
    ],
    '/design-hub/': [
        {
            img: caseStudies,
            title: 'Case Studies',
            url: '/downloads',
        },
        {
            img: siteSurveyTool,
            title: 'Site Survey Tool',
            onClick: () => scrollToSiteSurvey(),
        },
        {
            img: authorizedAgentPortal,
            title: 'Authorized Agent Portal',
            onClick: () => scrollToAgentPortal(),
        },
    ],
    '/services-hub': [
        {
            img: installationServices,
            title: 'Installation Services',
            onClick: () => scrollToInstallationServices(),
        },
        // {
        //     img: managedServices,
        //     title: 'Managed Services',
        //     onClick: () => scrollToManagedServices(),
        // },
        {
            img: supportDesk,
            title: 'Support Desk',
            url: 'https://support.profound-tech.com/support/home',
            isExternal: true,
        },
    ],
    '/services-hub/': [
        {
            img: installationServices,
            title: 'Installation Services',
            onClick: () => scrollToInstallationServices(),
        },
        // {
        //     img: managedServices,
        //     title: 'Managed Services',
        //     onClick: () => scrollToManagedServices(),
        // },
        {
            img: supportDesk,
            title: 'Support Desk',
            url: 'https://support.profound-tech.com/support/home',
            isExternal: true,
        },
    ],
    '/services-hub/new-york-av-services': [
        {
            img: installationServices,
            title: 'Installation Services',
            onClick: () => scrollToInstallationServices(),
        },
        {
            img: supportDesk,
            title: 'Support Desk',
            url: 'https://support.profound-tech.com/support/home',
            isExternal: true,
        },
    ],
    '/services-hub/new-york-av-services/': [
        {
            img: installationServices,
            title: 'Installation Services',
            onClick: () => scrollToInstallationServices(),
        },
        {
            img: supportDesk,
            title: 'Support Desk',
            url: 'https://support.profound-tech.com/support/home',
            isExternal: true,
        },
    ],
    '/services-hub/los-angeles-av-services': [
        {
            img: installationServices,
            title: 'Installation Services',
            onClick: () => scrollToInstallationServices(),
        },
        {
            img: supportDesk,
            title: 'Support Desk',
            url: 'https://support.profound-tech.com/support/home',
            isExternal: true,
        },
    ],
    '/services-hub/los-angeles-av-services/': [
        {
            img: installationServices,
            title: 'Installation Services',
            onClick: () => scrollToInstallationServices(),
        },
        {
            img: supportDesk,
            title: 'Support Desk',
            url: 'https://support.profound-tech.com/support/home',
            isExternal: true,
        },
    ],
    '/services-hub/washington-dc-av-services': [
        {
            img: installationServices,
            title: 'Installation Services',
            onClick: () => scrollToInstallationServices(),
        },
        {
            img: supportDesk,
            title: 'Support Desk',
            url: 'https://support.profound-tech.com/support/home',
            isExternal: true,
        },
    ],
    '/services-hub/washington-dc-av-services/': [
        {
            img: installationServices,
            title: 'Installation Services',
            onClick: () => scrollToInstallationServices(),
        },
        {
            img: supportDesk,
            title: 'Support Desk',
            url: 'https://support.profound-tech.com/support/home',
            isExternal: true,
        },
    ],
    '/agent': [
        {
            img: installationServices,
            title: 'Resources',
            onClick: () => scrollToResources(),
        },
        {
            img: managedServices,
            title: 'Co-Marketing',
            onClick: () => scrollToCoMarketing(),
        },
        {
            img: supportDesk,
            title: 'Book a Demo',
            onClick: () => scrollToDemo(),
        }
    ],
    '/agent/': [
        {
            img: installationServices,
            title: 'Resources',
            onClick: () => scrollToResources(),
        },
        {
            img: managedServices,
            title: 'Co-Marketing',
            onClick: () => scrollToCoMarketing(),
        },
        {
            img: supportDesk,
            title: 'Book a Demo',
            onClick: () => scrollToDemo(),
        }
    ],
};

export const openHubspotChat = () => {
    if (typeof window.HubSpotConversations !== 'undefined') {
        window.HubSpotConversations.widget.open();
    }
};
