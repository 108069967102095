import styled from 'styled-components';
import { LazyLoadImage } from 'react-lazy-load-image-component';

export const Card = styled.div`
    width: fit-content;
    position: relative;
`;

export const CardImageBox = styled.div`
    position: relative;
    height: 390px;
`;

export const CardImage = styled(LazyLoadImage)`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 8px;
    object-fit: cover;
`;

export const CardTitle = styled.h2`
    position: absolute;
    bottom: 0;
    color: white;
    font-family: Poppins;
    font-size: 24px;
    font-weight: 700;
    line-height: 38px;
    width: 100%;
    padding:5px;
`;

export const CardDescription = styled.p`
    font-family: Poppins;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    margin-top: 10px;
    padding:0 5px;
`;

export const CardLink = styled.a``