import {
    Card,
    CardImageBox,
    CardImage,
    CardTitle,
    CardDescription,
    CardLink
} from './styled';
import { ServiceHubCardProps } from 'global/globalTypes';

function ServiceHubCard({
    image,
    background,
    title,
    description,
    alt,
    link
}: ServiceHubCardProps) {
    return (
        <Card>
          <CardLink href={link || undefined}>
            <CardImageBox>
                <CardImage
                    src={image}
                    alt={alt}
                    loading="lazy"
                    effect="blur"
                    placeholderSrc={background}
                    width="100%"
                    height="100%"
                />
                <CardTitle>{title}</CardTitle>
            </CardImageBox>
        </CardLink>
            <CardDescription dangerouslySetInnerHTML={{ __html: description }} />
        </Card>
    );
}

export default ServiceHubCard;
