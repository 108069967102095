import { FC, lazy } from 'react';

const Home = lazy(() => import('pages/Home'));
const AboutUs = lazy(() => import('pages/AboutUs'));
const OurServices = lazy(() => import('pages/ServiceHub'));
const ContactUs = lazy(() => import('pages/ContactUs'));
const ProductReviews = lazy(() => import('pages/ProductReviews'));
const ProductReview = lazy(() => import('pages/ProductReview'));
const PageNotFound = lazy(() => import('pages/PageNotFound'));
const DesignHub = lazy(() => import('pages/DesignHub'));
const PrivacyPolicy = lazy(() => import('pages/PrivacyPolicy'));
const Downloads = lazy(() => import('pages/Downloads'));
const RoomSurvey = lazy(() => import('pages/RoomSurvey'));
const Agent = lazy(() => import('pages/Agent'));
const CommercialAVSolution = lazy(() => import('pages/ServiceHub/CommercialAVSolution'));
const CrestronAVSolution = lazy(() => import('pages/ServiceHub/CrestronAVSolution'));
const MicrosoftTeamsConferencing = lazy(() => import('pages/ServiceHub/MicrosoftTeamsConferencing'));
const ResidentialAVSolution = lazy(() => import('pages/ServiceHub/ResidentialAVSolution'));
const ZoomConferencingSystems = lazy(() => import('pages/ServiceHub/ZoomConferencingSystems'));
const GovernmentAVSolution = lazy(() => import('pages/ServiceHub/GovernmentAVSolution'));
const OurServicesNewYork = lazy(() => import('pages/ServiceHub/NewYork'));
const OurServicesLosAngeles = lazy(() => import('pages/ServiceHub/LosAngeles'));
const OurServicesWashingtonDC = lazy(() => import('pages/ServiceHub/WashingtonDC'));



export interface IRoute {
    url: string;
    Component: FC;
}

export interface IRedirect {
    url: string;
    to: string;
}

export const routes: IRoute[] = [
    { url: '/', Component: Home },
    { url: '/services-hub', Component: OurServices },
    { url: '/about-us', Component: AboutUs },
    { url: '/contact-us', Component: ContactUs },
    { url: '/product-reviews', Component: ProductReviews },
    { url: '/product-reviews/:labelId', Component: ProductReview },
    { url: '/design-hub', Component: DesignHub },
    { url: '/agent', Component: Agent },
    { url: '/privacy-policy', Component: PrivacyPolicy },
    { url: '/downloads', Component: Downloads },
    { url: '/room-survey', Component: RoomSurvey },
    { url: '/services-hub/commercial-av-solution', Component: CommercialAVSolution },
    { url: '/services-hub/crestron-av-solutions', Component: CrestronAVSolution },
    { url: '/services-hub/microsoft-teams-conferencing', Component: MicrosoftTeamsConferencing },
    { url: '/services-hub/residential-av-solutions', Component: ResidentialAVSolution },
    { url: '/services-hub/zoom-conferencing-systems', Component: ZoomConferencingSystems },
    { url: '/services-hub/government-av-solutions', Component: GovernmentAVSolution },
    { url: '/services-hub/new-york-av-services', Component: OurServicesNewYork },
    { url: '/services-hub/los-angeles-av-services', Component: OurServicesLosAngeles },
    { url: '/services-hub/washington-dc-av-services', Component: OurServicesWashingtonDC },



    { url: '/*', Component: PageNotFound },
];

export const redirects: IRedirect[] = [
    // links
    { url: '/details', to: '/services-hub' },
    { url: '/details/details', to: '/services-hub' },
    { url: '/secondary/list', to: '/design-hub' }, // create
    { url: '/downloads', to: '/downloads' }, // to be determined
    { url: '/secondary/privacyPolicy', to: '/privacy-policy' },
    { url: '/roomBuilder/Gateway', to: '/room-builder' },
    { url: '/roomBuilder/roomBuilder', to: '/room-builder' },

    // anchors
    { url: '/details#what-make-us-successful', to: '/case-studies' },
    { url: '/details#what-we-do', to: '/design-hub' },
    { url: '/details#solutions-we-offer', to: '/services-hub' },
    { url: '/details#solutions-we-offer-our-products', to: '/design-hub' },
    { url: '/details#solutions-we-offer-our-process', to: '/services-hub' },
    { url: '/details#who-we-are', to: '/about-us' },
    { url: '/details#who-we-are-our-passion', to: '/about-us' },
    { url: '/details#who-we-are-our-culture', to: '/about-us' },
    { url: '/details#what-we-do-residential', to: '/services-hub' },
    { url: '/details#what-we-do-government', to: '/services-hub' },
];
